import { graphql } from 'gatsby'
import React from 'react'
import Exhibit from '../components/Exhibit'
import SEO from '../components/SEO'

const PartnerTemplate = ({ data, pageContext }) => {
  const {
    seoDescription,
    name,
    nameImage,
    introText,
    partnerWebsite,
    instagramHandle,
    facebookUrl,
    art,
  } = data.contentfulPartner

  return (
    <>
      <SEO title={name} description={seoDescription.seoDescription} />
      <Exhibit
        type="partner"
        title={name}
        name={name}
        nameImage={nameImage}
        label="The LAAB Partners"
        intro={introText}
        website={partnerWebsite}
        instagramHandle={instagramHandle}
        facebookUrl={facebookUrl}
        next={pageContext.next}
        art={art}
      />
    </>
  )
}

export default PartnerTemplate

export const pageQuery = graphql`
  query PartnerBySlug($slug: String!) {
    contentfulPartner(slug: { eq: $slug }) {
      seoDescription {
        seoDescription
      }
      name
      nameImage {
        file {
          url
        }
      }
      introText {
        raw
      }
      partnerWebsite
      instagramHandle
      facebookUrl
      art {
        type: __typename
        ... on Node {
          ... on ContentfulImage {
            title
            description
            file {
              file {
                details {
                  image {
                    width
                  }
                }
              }
              gatsbyImageData(quality: 80)
            }
          }
          ... on ContentfulVideo {
            title
            description
            vimeoId
          }
        }
      }
    }
  }
`
